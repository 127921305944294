.block-editor-inner-blocks .wp-block-checkout-owner-fields {
  user-select: none !important;
  pointer-events: none !important;
}

/*
 * ==============================================
 * Initial
 * ==============================================
 */
.tc-wb-block-component .description { margin: 16px 0 0 0; }
.tc-wb-block-component-first_name h5, .tc-wb-block-component-last_name h5 { display: none; }
.tc-wb-block-component textarea:focus-visible { outline: 0; box-shadow: 0 0 0 1px #50575e; }

@media screen and ( min-width: 441px ) {
  .tc-wb-block-component-first_name, .tc-wb-block-component-last_name { display: inline-block; width: calc(50% - 6px); }
  .wc-block-components-form .tc-wb-block-component .wc-block-components-text-input { margin-top: 0; }
  .tc-wb-block-component-first_name { margin-right: 6px; }
  .tc-wb-block-component-last_name { float:right; margin-left: 6px; }
}

/*
 * ==============================================
 * Error
 * ==============================================
 */
.wp-block-checkout-owner-fields-errors {
  margin-top: 60px;
}

.wp-block-checkout-owner-fields-errors svg {
  height: fit-content;
}

.tc-wb-block-component.has-error input:focus,
.tc-wb-block-component.has-error textarea:focus {
  box-shadow: 0 0 0 1px #cc1818 !important;
  outline: 2px solid #cc1818 !important;
}

.tc-wb-block-component.has-error div,
.tc-wb-block-component.has-error h5,
.tc-wb-block-component.has-error label,
.tc-wb-block-component.has-error span,
.tc-wb-block-component.has-error textarea,
.tc-wb-block-component.has-error textarea::placeholder,
.tc-wb-block-component.has-error input {
  border-color: #cc1818 !important;
  color: #cc1818 !important;
}

/*
 * ==============================================
 * Combobox
 * ==============================================
 */
.tc-wb-block-combobox > div > div > div > div > div {
  height: inherit;
  padding-left: 0;
  padding-right: 0;
}

/*
 * ==============================================
 * Radio
 * ==============================================
 */
.tc-wb-block-components-radio-control__input input {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border: 2px solid #50575e;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 1.5em;
  min-height: 24px;
  min-width: 24px;
  width: 1.5em;
  margin-top: 0;
}

.tc-wb-block-components-radio-control__input input:checked:before {
  background: #000;
  border-radius: 50%;
  content: "";
  display: block;
  height: 0.75em;
  left: 50%;
  margin: 0;
  min-height: 12px;
  min-width: 12px;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 0.75em;
}

.tc-wb-block-components-radio-control__input input:checked + label {
  font-weight: 700;
}

.tc-wb-block-components-radio-control__input .components-radio-control__option {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-top: 1em;
}

.tc-wb-block-components-radio-control__input .components-radio-control__option:first-child {
  margin-top: 0;
}

.tc-wb-block-components-radio-control__input .components-radio-control__option label {
  font-size: 1.41346em;
  font-weight: 400;
  cursor: pointer;
  font-family: NonBreakingSpaceOverride, "Hoefler Text", Garamond, "Times New Roman", serif;
  margin-bottom: 0;
}
